import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Testimonial extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner pt-0',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap illustration-section-08',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Rien de mieux que des avis',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-top">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-01.jpg')}
                        alt="Testimonial 01"
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        Grâce au Dada Tennis, le TC16 a réussi à faire progresser de très bon joueur grâce au côté ludique de l’entraînement de zone. Il permettait également de rendre l’entraînement plus compétitif !
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Julien
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">TC 16</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-top" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header">
                    <div className="testimonial-item-image">
                      <Image
                        src={require('./../../assets/images/testimonial-image-02.jpg')}
                        alt="Testimonial 02"
                        width={56}
                        height={56} />
                    </div>
                    <div className="testimonial-item-content">
                      <p className="text-sm mb-0">
                        Très satisfait de ce système qui permet tant qu’aux confirmés qu’aux débutants de viser des zones et de pouvoirs se confronter sur un scoring! Merci Dada !
                      </p>
                    </div>
                    <div className="testimonial-item-footer text-xxs tt-u fw-500 mt-32 mb-0 pt-24 has-top-divider">
                      <span className="testimonial-item-name text-color-high">
                        Cyrille
                      </span>
                      <span className="text-color-low"> - </span>
                      <span className="testimonial-item-link">
                        <a href="#0">TC MJC Mont-Mesly Créteil</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>                          

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;
    
export default Testimonial;