import React from 'react';
// import sections
import GenericSection from '../components/sections/GenericSection';

class LegalMention extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GenericSection>
          <div className="container-xs">
            <h2 className="mt-0">Politique de confidentialité</h2>
            <p>
              Chez Dada Tennis, la protection de vos données personnelles est une priorité. Lors de votre utilisation du site dadatennis.com (ci-après le « Site »), nous sommes amenés à recueillir des données à caractère personnel vous concernant. Le but de cette politique est de vous informer sur les modalités selon lesquelles nous traitons ces données en conformité avec le Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après le « RGPD »).
            </p>
            <h4>1. Qui est le responsable de traitement ?</h4>
            <p>
              Le responsable de traitement est la société SOFARLAB, Société par actions simplifiée, immatriculée au RCS de Paris sous le n° 887 719 706 et dont le siège social est situé au 11 rue de Lourmel, Paris (ci-après « Nous »).
            </p>
            <h4>2. Quelles données collectons-nous ?</h4>
            <p>
              Une donnée à caractère personnel est une donnée qui permet d’identifier un individu directement ou par recoupement avec d’autres données. Nous collectons uniquement des données d’identification (notamment vos nom, prénom, entreprise, adresse email et postale, numéro de téléphone). Les données obligatoires sont indiquées lorsque vous nous fournissez vos données. Elles sont signalées par un astérisque et sont nécessaires pour vous fournir nos services.
            </p>
            <h4>3. Sur quelles bases légales, pour quelles finalités et pendant combien de temps conservons- nous vos données personnelles ?</h4>
            <table>
              <thead>
                  <tr>
                      <th>Finalités</th>
                      <th>Bases légales	</th>
                      <th>Durées de conservation</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>Constituer un fichier de clients et prospects</td>
                      <td>Notre intérêt légitime à développer et promouvoir notre activité</td>
                      <td>Pour les clients : les données sont conservées pendant toute la durée de la relation commerciale et sont supprimées à l’expiration d’un délai de 3 ans à compter de la fin de la relation commerciale. Pour les prospects : les données sont conservées pendant un délai de 3 ans à compter de votre dernier contact. En tout état de cause, vos données peuvent être archivées à des fins probatoires pendant une durée de 5 ans.</td>
                  </tr>
                  <tr>
                      <td>Gérer les demandes d’exercice de droits</td>
                      <td>Obligations légales et règlementaires</td>
                      <td>Si nous vous demandons un justificatif d’identité : nous le conservons seulement pendant le temps nécessaire à la vérification d’identité. Une fois la vérification effectuée, le justificatif est supprimé. Si vous exercez votre droit d’opposition à exercer de la prospection : nous conservons cette information pendant 3 ans.</td>
                  </tr>
                  <tr>
                      <td>Adresser des newsletters, sollicitations et messages promotionnels</td>
                      <td>Notre intérêt légitime à développer et promouvoir notre activité</td>
                      <td>Les données sont conservées pendant 3 ans à compter de votre dernier contact.</td>
                  </tr>
                  <tr>
                      <td>Répondre à d’information vos demandes</td>
                      <td>Notre intérêt légitime à développer et promouvoir notre activité</td>
                      <td>Les données sont conservées pendant le temps nécessaire au traitement de votre demande d’information et supprimées une fois la demande d’information traitée.</td>
                  </tr>
                  <tr>
                      <td>Communiquer vos informations au livreur</td>
                      <td>Notre intérêt légitime à exécuter notre activité</td>
                      <td>Pour les clients : les données sont conservées pendant toute la durée de la relation commerciale et sont supprimées à l’expiration d’un délai de 3 ans à compter de la fin de la relation commerciale. Pour les livreurs : les données sont conservées pendant un délai de 3 ans à compter de votre dernier contact. En tout état de cause, vos données peuvent être archivées à des fins probatoires pendant une durée de 5 ans.</td>
                  </tr>
            </tbody>
            </table>
            <h4>4. Qui sont les destinataires de vos données ?</h4>
            <ul>
              <li>Mailchimp</li>
              <li>Mongodb</li>
              <li>AWS</li>
              <li>SendinBlue</li>
              <li>Twilio</li>
              <li>Slack</li>
              <li>Crisp</li>
            </ul>
            <h4>5. Vos données sont-elles susceptibles d’être transférées hors de l’Union européenne ?</h4>
            <p>
              Vos données sont conservées et stockées pendant toute la durée des traitements sur les serveurs de la société AWS , situés dans l’Union européenne. Dans le cadre des outils que nous utilisons (voir article sur les destinataires concernant nos sous-traitants), vos données sont susceptibles de faire l’objet de transferts hors de l’Union européenne. Le transfert de vos données dans ce cadre est sécurisé au moyen des outils suivants :
            </p>
            <ul>
              <li>Soit ces données sont transférées dans un pays ayant été jugé comme offrant un niveau de protection adéquat par une décision de la Commission européenne ;</li>
              <li>Soit nous avons conclu avec nos sous-traitants un contrat spécifique encadrant les transferts de vos données en dehors de l’Union européenne, sur la base des clauses contractuelles types entre un responsable de traitement et un sous-traitant approuvées par la Commission européenne ;</li>
              <li>Soit nous avons recours aux garanties appropriées prévues par la réglementation applicable.</li>
            </ul>
            <h4>6. Quels sont vos droits sur vos données ?</h4>
            <p>Vous disposez des droits suivants s’agissant de vos données personnelles :</p>
            <ul>
              <li>Droit à l’information : c’est justement la raison pour laquelle nous avons rédigé la présente politique. Ce droit est prévu par les articles 13 et 14 du RGPD.</li>
              <li>Droit d’accès : vous avez le droit d’accéder à tout moment à l’ensemble de vos données personnelles, en vertu de l’article 15 du RGPD.</li>
              <li>Droit de rectification : vous avez le droit de rectifier à tout moment vos données personnelles inexactes, incomplètes ou obsolètes conformément à l’article 16 du RGPD</li>
              <li>Droit à la limitation : vous avez le droit d’obtenir la limitation du traitement de vos données personnelles dans certains cas définis à l’article 18 du RGPD.</li>
              <li>Droit à l’effacement : vous avez le droit d’exiger que vos données personnelles soient effacées, et d’en interdire toute collecte future pour les motifs énoncés à l’article 17 du RGPD</li>
              <li>Droit d’introduire une réclamation auprès d’une autorité de contrôle compétente (en France, la CNIL), si vous considérez que le traitement de vos données personnelles constitue une violation des textes applicables. (Article 77 du RGPD)</li>
              <li>Droit de définir des directives relatives à la conservation, à l’effacement et à la communication de vos données personnelles après votre mort, conformément à l’article 40-1 de la loi informatique et libertés.</li>
              <li>Droit de retirer votre consentement à tout moment : pour les finalités fondées sur le consentement, l’article 7 du RGPD dispose que vous pouvez retirer votre consentement à tout moment. Ce retrait ne remettra pas en cause la légalité du traitement effectué avant le retrait.</li>
              <li>Droit à la portabilité : selon certaines conditions précisées à l’article 20 du RGPD, vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un format standard lisible par machine et d’exiger leur transfert au destinataire de votre choix.</li>
              <li>Droit d’opposition : en vertu de l’article 21 du RGPD, vous avez le droit de vous opposer au traitement de vos données personnelles. Notez toutefois que nous pourrons maintenir leur traitement malgré cette opposition, pour des motifs légitimes ou la défense de droits en justice.</li>
            </ul>
            <p>Vous pouvez exercer ces droits en nous écrivant aux coordonnées ci-dessous. Nous pourrons vous demander à cette occasion de nous fournir des informations ou documents complémentaires pour justifier votre identité.</p>
            <h4>7. Point de contact en matière de données personnelles</h4>
            <p>Email de contact : hello@dadatennis.com Adresse de contact : 11 rue de Lourmel, 75015, Paris</p>
            <h4>8. Modifications</h4>
            <p>Nous pouvons modifier à tout moment la présente politique, afin notamment de nous conformer à toutes évolutions réglementaires, jurisprudentielles, éditoriales ou techniques. Ces modifications s’appliqueront à la date d’entrée en vigueur de la version modifiée. Vous êtes donc invité à consulter régulièrement la dernière version de cette politique. Néanmoins, nous vous tiendrons informé(e) de toute modification significative de la présente politique de confidentialité. Entrée en vigueur : 01/01/2022</p>
          </div>
        </GenericSection>

      </React.Fragment>
    );
  }
}

export default LegalMention;